var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app flex-row align-items-center guest" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c(
          "b-row",
          { staticClass: "justify-content-center" },
          [
            _c(
              "b-col",
              { attrs: { xs: "12", md: "8", lg: "6" } },
              [
                _c(
                  "b-card-group",
                  [
                    _c(
                      "b-card",
                      { staticClass: "p-3", attrs: { "no-body": "" } },
                      [
                        _c("b-card-body", [
                          _c(
                            "form",
                            {
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.validateBeforeSubmit($event)
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass:
                                  "mx-auto d-block mb-4 navbar-brand-full",
                                attrs: {
                                  src: "img/brand/invoice-logo-2019.png",
                                  height: "95",
                                  alt: "RTNEST Inc. Logo"
                                }
                              }),
                              _c("h1", [_vm._v("Reset Password")]),
                              _c("flash-message", {
                                attrs: { autoHide: "", variant: "success" }
                              }),
                              _c("flash-message", {
                                attrs: { variant: "danger" }
                              }),
                              _c(
                                "b-input-group",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.tokenExpired,
                                      expression: "!tokenExpired"
                                    }
                                  ],
                                  staticClass: "mb-2"
                                },
                                [
                                  _c(
                                    "b-input-group-prepend",
                                    [
                                      _c("b-input-group-text", [
                                        _c("i", { staticClass: "fa fa-lock" })
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.password,
                                        expression: "password"
                                      },
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: {
                                          required: true,
                                          min: 14,
                                          regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/
                                        },
                                        expression:
                                          "{ required: true, min: 14, regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/ }"
                                      }
                                    ],
                                    ref: "password",
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "password",
                                      placeholder: "Enter new password",
                                      name: "password"
                                    },
                                    domProps: { value: _vm.password },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.password = $event.target.value
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-input-group",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.tokenExpired,
                                      expression: "!tokenExpired"
                                    }
                                  ],
                                  staticClass: "mb-2"
                                },
                                [
                                  _c(
                                    "b-input-group-prepend",
                                    [
                                      _c("b-input-group-text", [
                                        _c("i", { staticClass: "fa fa-lock" })
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.password2,
                                        expression: "password2"
                                      },
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|confirmed:password",
                                        expression:
                                          "'required|confirmed:password'"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "password",
                                      placeholder:
                                        "Enter Password Confirmation",
                                      "data-vv-as": "password",
                                      name: "password2"
                                    },
                                    domProps: { value: _vm.password2 },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.password2 = $event.target.value
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("password2"),
                                    expression: "errors.has('password2')"
                                  }
                                ],
                                staticClass: "fa fa-exclamation-triangle"
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has("password2"),
                                      expression: "errors.has('password2')"
                                    }
                                  ],
                                  staticClass: "help is-danger"
                                },
                                [_vm._v("The passwords must match.")]
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has("password"),
                                      expression: "errors.has('password')"
                                    }
                                  ]
                                },
                                [
                                  _c("h6", { staticClass: "mt-2" }, [
                                    _c("i", {
                                      staticClass: "fa fa-exclamation-triangle"
                                    }),
                                    _vm._v(" Your password must: ")
                                  ]),
                                  _c(
                                    "ol",
                                    {
                                      staticStyle: {
                                        "list-style-type": "upper-greek"
                                      }
                                    },
                                    [
                                      _c("li", [
                                        _vm._v(
                                          "Include an UPPER and lowercase letter."
                                        )
                                      ]),
                                      _c("li", [_vm._v("Include a number.")]),
                                      _c("li", [
                                        _vm._v(
                                          'Include one or more of these special characters: .@$!%*#?><")(^-_ '
                                        )
                                      ]),
                                      _c("li", [
                                        _vm._v("At least 14s characters")
                                      ])
                                    ]
                                  )
                                ]
                              ),
                              _vm.url == "invoice.rtnest.ca" ||
                              _vm.url == "www.invoice.rtnest.ca"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.robot,
                                        expression: "robot"
                                      },
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'"
                                      }
                                    ],
                                    attrs: { type: "hidden", name: "robot" },
                                    domProps: { value: _vm.robot },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.robot = $event.target.value
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.tokenExpired,
                                      expression: "tokenExpired"
                                    }
                                  ],
                                  staticClass: "help is-danger"
                                },
                                [
                                  _c("h5", { staticClass: "mt-2" }, [
                                    _c("i", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.tokenExpired,
                                          expression: "tokenExpired"
                                        }
                                      ],
                                      staticClass: "fa fa-exclamation-triangle"
                                    }),
                                    _vm._v(
                                      " This link is\n                    expired please\n                    send a new reset password request "
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "b-input-group",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.tokenExpired,
                                      expression: "!tokenExpired"
                                    }
                                  ],
                                  staticClass: "cap"
                                },
                                [
                                  _c("vue-recaptcha", {
                                    ref: "reCaptcha",
                                    staticClass: "mt-2",
                                    attrs: {
                                      sitekey:
                                        "6LfAwYcUAAAAACZe3pwLB7otQzENyRr_UlZshjx6"
                                    },
                                    on: {
                                      verify: _vm.onCaptchaVerified,
                                      expired: _vm.onCaptchaExpired
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("robot"),
                                    expression: "errors.has('robot')"
                                  }
                                ],
                                staticClass:
                                  "fa fa-exclamation-triangle mt-2 mb-2"
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has("robot"),
                                      expression: "errors.has('robot')"
                                    }
                                  ],
                                  staticClass: "mb-3 help is-danger"
                                },
                                [_vm._v("The reCaptcha is required.")]
                              ),
                              _c(
                                "b-row",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.tokenExpired,
                                      expression: "!tokenExpired"
                                    }
                                  ],
                                  staticClass: "mt-3"
                                },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "col-md-5",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "w-100 px-5",
                                          attrs: {
                                            variant: "primary",
                                            type: "submit"
                                          }
                                        },
                                        [_vm._v("Reset Password")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }