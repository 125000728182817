<template>
  <div class="app flex-row align-items-center guest">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col xs="12" md="8" lg="6">
          <b-card-group>
            <b-card no-body class="p-3">
              <b-card-body>
                <form @submit.prevent="validateBeforeSubmit">
                  <img class="mx-auto d-block mb-4 navbar-brand-full" src="img/brand/invoice-logo-2019.png" height="95"
                    alt="RTNEST Inc. Logo">
                  <h1>Reset Password</h1>
                  <!-- <p v-show="!tokenExpired" class="text-muted">Enter new password</p> -->
                  <flash-message autoHide variant="success"></flash-message>
                  <flash-message variant="danger"></flash-message>
                  <b-input-group v-show="!tokenExpired" class="mb-2">
                    <b-input-group-prepend>
                      <b-input-group-text><i class="fa fa-lock"></i></b-input-group-text>
                    </b-input-group-prepend>
                    <input type="password" class="form-control" v-model="password"
                      v-validate="{ required: true, min: 14, regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/ }"
                      placeholder="Enter new password" name="password" ref="password" />
                  </b-input-group>
                  <b-input-group v-show="!tokenExpired" class="mb-2">
                    <b-input-group-prepend>
                      <b-input-group-text><i class="fa fa-lock"></i></b-input-group-text>
                    </b-input-group-prepend>
                    <input type="password" class="form-control" v-model="password2"
                      v-validate="'required|confirmed:password'" placeholder="Enter Password Confirmation"
                      data-vv-as="password" name="password2" />
                  </b-input-group>
                  <i v-show="errors.has('password2')" class="fa fa-exclamation-triangle"></i> <span
                    v-show="errors.has('password2')" class="help is-danger">The passwords must match.</span>
                  <span v-show="errors.has('password')">
                    <h6 class="mt-2"><i class="fa fa-exclamation-triangle"></i> Your password must: </h6>
                    <ol style="list-style-type: upper-greek;">
                      <li>Include an UPPER and lowercase letter.</li>
                      <li>Include a number.</li>
                      <li>Include one or more of these special characters: .@$!%*#?&gt&lt&quot)(^-_ </li>
                      <li>At least 14s characters</li>
                    </ol>
                  </span>
                  <input v-if="url == 'invoice.rtnest.ca' || url == 'www.invoice.rtnest.ca'" type="hidden" name="robot"
                    v-model="robot" v-validate="'required'" />

                  <span v-show="tokenExpired" class="help is-danger">
                    <h5 class="mt-2"><i v-show="tokenExpired" class="fa fa-exclamation-triangle"></i> This link is
                      expired please
                      send a new reset password request </h5>
                  </span>

                  <b-input-group v-show="!tokenExpired" class="cap">
                    <vue-recaptcha ref="reCaptcha" @verify="onCaptchaVerified" @expired="onCaptchaExpired" class="mt-2"
                      sitekey="6LfAwYcUAAAAACZe3pwLB7otQzENyRr_UlZshjx6">
                    </vue-recaptcha>
                  </b-input-group>
                  <i v-show="errors.has('robot')" class="fa fa-exclamation-triangle mt-2 mb-2"></i> <span
                    v-show="errors.has('robot')" class="mb-3 help is-danger">The reCaptcha is required.</span>
                  <b-row v-show="!tokenExpired" class="mt-3">
                    <b-col cols="12" class="col-md-5">
                      <b-button variant="primary" type="submit" class="w-100 px-5">Reset Password</b-button>
                    </b-col>
                  </b-row>
                </form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Vue from 'vue'
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: 'ResetPassword',
  components: {
    vSelect,
    VueRecaptcha
  },
  data: function () {
    return {
      tokenExpired: false,
      password: '',
      password2: '',
      robot: '',
      url: '',
    }
  },
  mounted() {
    this.url = window.location.hostname;
  },
  beforeCreate() {
    //do something before mounting vue instance
    if (!this.$session.has('query')) {
      this.$session.set('query', '');
    }
    if (!this.$session.has('orderBy')) {
      this.$session.set('orderBy', { ascending: true, column: false });
    }
    if (!this.$session.has('page')) {
      this.$session.set('page', 1);
    }
  },
  methods: {
    onCaptchaExpired: function () {
      this.robot = '';
    },
    onCaptchaVerified: function (recaptchaToken) {
      this.robot = 'true';
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$axios.get("/check/token?t=" + this.$route.query.t)
            .then((result) => {
              console.log("result :", result);
              let role = result.data.role;
              let id = result.data.id;
              let data = JSON.stringify({
                password: this.password,
                confirm_password: this.password2,
                id: id,
                role: role
              });
              this.$axios.post("/" + role + '/edit/password/external', data)
                .then(response => {
                  this.flash({ message: 'Reset Successful', variant: 'success' });
                  this.$router.push("/")
                  return;
                })
                .catch(error => {
                  if (error.response.data.code == 'USED_PASSWORD')
                    this.$toasted.show('Already used password please use another password.', { type: 'error', duration: '5000' })
                  else
                    this.$toasted.show('Reset Password unsuccessful please check new password or your connection.', { type: 'error', duration: '5000' })

                  this.robot = '';
                  this.$refs.reCaptcha.reset();
                });
            }).catch((err) => {
              this.$toasted.show('Your link is expired', { type: 'error', duration: '3000' });
              this.$router.push("/reset-password-expired");
              console.log(err);
            });

        }
      });
    },
  }
}
</script>

<style lang="scss">
.select-account {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 10px;
}

.fa-exclamation-triangle {
  margin-right: 7px;
}

.dropdown-icon {
  right: 15px;
  top: 10px;
  z-index: 99;
}
</style>
